<template>
    <div class="videolist">
        <!-- <BrandList v-on:clickBrandBrand="clickBrandBrand" :activeName="id" :type="1"></BrandList> -->
        <div class="brand">
            <TitleList v-on:clickBrandBrand="clickBrandBrand" :activeName="id" :type="1" ></titleList>
        </div>
        <div class="item" v-for="(item, index) in list" :key="index" @click="toPlay(item.link)">
            <div class="hd">
                <div class="enname Ellipses1">{{item.en_name}}</div>
                <div class="name Ellipses1">{{item.name}}</div>
            </div>
            <div class="poster">
                <div class="mask">
                    <i class="iconfont iconbofang"></i>
                </div>
                <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.cover" />
            </div>
        </div>

        <div class="Loading" v-if="loading">
            <Loading size="24px">加载中...</Loading>
        </div>
        <div class="Loading" v-if="finished">没有更多了~</div>

        <Overlay :show="show" @click="show = false">
            <div class="Overlaywrapper">
                <div class="videoblock">
                    <video ref="videoPlayer" :src="videourl" controls v-if="show"></video>

                    <Icon name="close" class="close" @click.stop="close()" />
                </div>
            </div>
        </Overlay>
    </div>
</template>
<script>
import { VideoList } from "@api/tasting";
import { Empty, Overlay, Icon } from "vant";
import { Image as VanImage } from 'vant';
import BrandList from "./../components/brandList";
import TitleList from "./../components/titleList";
export default {
    name: "videolist",
    components: {
        VanImage, Empty, Overlay, Icon, BrandList,TitleList
    },
    props: {},
    data: function() {
        return {
            id: '',
            show: false,

            videourl: '',

            list: [],
            loading: false,
            finished: false,
            page: 1,
            limit: 5,
        };
    },
    created: function() {
        this.id=Number(this.$route.query.brand_id);
    },
    mounted: function() {
        this.getVideoList();
        var that = this;
        window.addEventListener("scroll", function(event) {
            var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                that.Loadmore();
            }
        });
    },
    methods: {
        clickBrandBrand: function (id) {
            this.id=id;
            this.list=[];
            this.loading=false;
            this.finished=false;
            this.page=1;
            this.limit=5;
            this.getVideoList();
            this.$router.push({ path: '/Tasting/video/list?brand_id=' + id });
        },
        Loadmore: function() {
            if(this.loading) return;
            if(this.finished) return;
            this.getVideoList();
        },
        getVideoList: function() {
            this.loading = true;
            VideoList({
                brand_id: this.id,
                page: this.page,
                limit: this.limit,
            }).then(res => {
                var listdata = res.data.data;
                listdata.forEach((item)=>{
                    this.list.push(item);
                })
                this.loading = false;
                this.page++;
                if(listdata.length<5){
                    this.finished = true;
                }
            })
            .catch(err => {
                this.loading = false;
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        changeactive: function(e) {
            // this.$router.push({ path: this.TabsList[e].url });
        },
        close: function() {
            this.show=false;
        },
        toPlay(url){
            this.show=true;
            this.videourl=url;
            const that = this;
            setTimeout(() => {
                that.$refs.videoPlayer.play();
            }, 100);
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        .van-tabs__line::before{
            background:#f4f4f4;
            border-left: 1px solid #b6b6b6;
            border-top: 1px solid #b6b6b6;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform: rotate(45deg);
        }
    }
}
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.van-overlay{z-index:3000;}
.Overlaywrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .videoblock {
        width: 100%;position: relative;
        video{width: 100%;max-height: 10rem;}
        .close{position: fixed;top: 0.3rem;right: 0.2rem;color: #fff;font-size: 0.6rem;}
    }
}
.videolist{
    .brand{
            width: 6.5rem;
            background: #f4f4f4;
            position: fixed;
            z-index: 1100;
            top:0.02rem
        }
    .item{
        background: #fff;margin-top: 0.2rem;
        .hd{padding:0.1rem 0.2rem;line-height:0.4rem;font-size:0.28rem;color:#333;}
        .poster{
            width:100%;height: 4rem;background:#000;position: relative;
            .mask{background: rgb(0, 0, 0, 0.3);top:0;left: 0;right: 0;bottom:0;position: absolute;display: flex;align-items: center;justify-content: center;}
            i{color: #ffffff;font-size: 1rem;}
        }
    }
}
</style>
